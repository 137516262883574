import type { IconName } from '@st/ui/components/StIcon/types'
import { delay } from '@st/utils'

interface FooterItem {
  title: string
  children: {
    title: string
    link: string
    linkMobile: string
    isNewWindow?: boolean
  }[]
}

export function useFooter() {
  const { t } = useI18n()
  const { mainCategories } = storeToRefs(useCasinoStore())
  const {
    isShownFooterDocuments,
    isShownFooterCopyright,
    isShownFooterLicense,
    isShownLicenceValidator,
  } = useDomainFeatures()
  const { supportUrl } = useSupportUrl()

  const termsUrl = usePublicFileUrl('publicFileTerms')
  const privacyPolicyUrl = usePublicFileUrl('publicFilePrivacyPolicy')
  const amlPolicyUrl = usePublicFileUrl('publicFileAmlPolicy')
  const betTermsUrl = usePublicFileUrl('publicFileBetTerms')

  const responsibleGamblingUrl = usePublicFileUrl(
    'publicFileResponsibleGambling',
  )

  const socialMedia: { path: string; icon: IconName }[] = [
    { path: 'https://discord.gg/betone', icon: 'discord' },
    { path: 'https://x.com/bet_one_com', icon: 'twitter' },
    { path: 'https://t.me/betone_com', icon: 'telegram' },
  ]

  const popularCategories = computed(() =>
    mainCategories.value.map((category) => ({
      title: category.name || category.code,
      link: `/casino/categories/${category.code}`,
      linkMobile: `/mobile/casino/categories/${category.code}`,
    })),
  )

  const isMounted = ref(false)
  onMounted(async () => {
    await delay(0)
    isMounted.value = true
  })

  const footerItems = computed<FooterItem[]>(() => [
    {
      title: t('footer.casino'),
      children: [
        {
          title: t('footer.casinoMain'),
          link: '/casino',
          linkMobile: '/mobile/casino',
        },
        ...popularCategories.value,
        {
          title: t('footer.providers'),
          link: '/casino/providers',
          linkMobile: '/mobile/casino/providers',
        },
      ],
    },
    {
      title: t('footer.sport'),
      children: [
        {
          title: t('footer.sportMain'),
          link: '/sport',
          linkMobile: '/mobile/sport',
        },
        {
          title: t('footer.liveEvents'),
          link: '/sport/live',
          linkMobile: '/mobile/sport/live',
        },
        {
          title: t('footer.upcoming'),
          link: '/sport/upcoming',
          linkMobile: '/mobile/sport/upcoming',
        },
        {
          title: t('footer.allEvents'),
          link: '/sport/all',
          linkMobile: '/mobile/sport/all',
        },
      ],
    },
    {
      title: t('footer.aboutUs'),
      children: [
        {
          title: t('footer.supportService'),
          link: '/support',
          linkMobile: '/mobile/support',
        },
        {
          title: t('footer.help'),
          link: supportUrl.value,
          linkMobile: supportUrl.value,
          isNewWindow: true,
        },
      ],
    },
    ...(isShownFooterDocuments.value && isMounted.value
      ? [
          {
            title: t('footer.information'),
            children: [
              {
                title: t('footer.termsAndConditions'),
                link: termsUrl.value,
                linkMobile: termsUrl.value,
                isNewWindow: true,
              },
              {
                title: t('footer.privacyPolicy'),
                link: privacyPolicyUrl.value,
                linkMobile: privacyPolicyUrl.value,
                isNewWindow: true,
              },
              {
                title: t('footer.responsibleGaming'),
                link: responsibleGamblingUrl.value,
                linkMobile: responsibleGamblingUrl.value,
                isNewWindow: true,
              },
              {
                title: t('footer.amlPolicy'),
                link: amlPolicyUrl.value,
                linkMobile: amlPolicyUrl.value,
                isNewWindow: true,
              },
              {
                title: t('footer.betTerms'),
                link: betTermsUrl.value,
                linkMobile: betTermsUrl.value,
                isNewWindow: true,
              },
            ],
          },
        ]
      : []),
  ])

  const url = useRequestURL()

  const copyright = computed(() => {
    if (!isShownFooterCopyright.value) return null

    const domain = url.hostname
    const year = new Date().getFullYear()

    const license = isShownFooterLicense.value
      ? 'Continent Gaming N.V. is licensed and regulated by Gaming Curacao (license no. 365/JAZ Sub-License GLH-OCCHKTW0703092022).'
      : ''

    return t('footer.copyright', { year, domain, license })
  })

  return {
    socialMedia,
    footerItems,
    copyright,
    isShownLicenceValidator,
  }
}
