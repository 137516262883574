<template>
  <StSelect
    v-model="selectedLanguage"
    :items="languages"
    size="s"
    class="select-language"
    :class="props.type"
  />
</template>

<script setup lang="ts">
import { useLanguageSelect } from '@st/i18n/composables/useLanguageSelect'

const props = withDefaults(
  defineProps<{
    type?: 'base' | 'primary'
  }>(),
  {
    type: 'base',
  },
)

const { options: languages, selectedLanguage } = useLanguageSelect()
</script>

<style scoped>
.select-language {
  user-select: none;
  max-width: 120px;

  & :deep(.options) {
    top: auto;
    bottom: 40px;
    background-color: var(--background-base);
  }

  & :deep(.option) {
    gap: var(--spacing-100);
    justify-content: flex-start;
    width: 155px;
    padding: var(--spacing-100) 0 var(--spacing-100) var(--spacing-100);

    &:hover {
      background-color: var(--background-primary);
    }
  }

  & :deep(.amount) {
    align-items: flex-start;
  }

  & :deep(.input-wrapper) {
    background-color: var(--background-base);
  }

  & :deep(.options-column) {
    background-color: var(--background-base);
    margin-right: -12px; /* stylelint-disable-line */
  }

  & :deep(.input-wrapper .third-value) {
    text-align: left;
  }

  &.primary {
    & :deep(.options-column) {
      background-color: var(--background-primary);
    }

    & :deep(.input-wrapper) {
      background-color: var(--background-primary);
    }
  }
}
</style>
